import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import styled from 'styled-components';

import routeData from 'config/routeData';

export default function PhilingNavigator() {
  const router = useRouter();

  const handleIcon = (path, icon) => {
    if (path === '/pick') {
      return router.pathname.startsWith('/pick') ? icon.active : icon.default;
    }

    if (path === '/community') {
      return router.pathname.startsWith('/community') ? icon.active : icon.default;
    }

    return router.pathname === path ? icon.active : icon.default;
  };

  return (
    <Navigator>
      <NavUl>
        {routeData.map(({ label, path, icon, badge }, idx) => (
          <li key={idx}>
            <Link href={path} prefetch={false} passHref legacyBehavior>
              <NavA>
                <TabWrap>
                  {badge && <RedDot />}
                  <Image src={handleIcon(path, icon)} alt={label} />
                  <Label>{label}</Label>
                </TabWrap>
              </NavA>
            </Link>
          </li>
        ))}
      </NavUl>
    </Navigator>
  );
}

const NavA = styled.a`
  text-decoration: none;
  color: #666;
`;

const NavUl = styled.ul`
  display: block;
  display: flex;
  justify-content: space-around;
`;

const RedDot = styled.div`
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: red;
  position: absolute;
  top: 10px;
  right: 10px;
`;

const Navigator = styled.nav`
  position: fixed;
  max-width: 420px;
  width: 100%;
  margin: 0 auto;
  margin-left: -1.4rem;
  left: auto;
  bottom: 0;
  z-index: 99;
  border-top: 1px solid #bbbbbb;
  background-color: #fff;
`;

const TabWrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.8rem 0;
  cursor: pointer;
  opacity: ${({ disabled }) => disabled && '0.2'};
  width: 5rem;
`;

const Image = styled.img`
  width: 3rem;
  aspect-ratio: 1;
`;

const Label = styled.div`
  font-size: 1rem;
  font-weight: bold;
  color: #666;
`;
